$color-main-dark: #191c1f;
$color-main-light: #fff;

$color-error: #e30324;
$color-warning: #f88e00; // TODO Remove redefinition of the same hexa in Descours
$color-success: #008800;

$color-disabled-dark: #666;
$color-disabled-light: #d2d2d2; // Same as $color-gray83

$color-primary: #005296;
$color-primary-darken: shade($color-primary, 20%);
$color-secondary: #0789f4; // warning: no a11y compliance
$color-secondary-darken: shade($color-secondary, 20%);
$color-sub-secondary: #03488f; // TODO Remove redefinition of the same hexa in Descours
$color-dark-grey: #515151;

$color-tertiary-11: #7cc5b9; // map with dark foreground

$color-background-default: $color-main-light;
$color-text-default: $color-main-dark;
$color-text-light: #7f7f7f; // TODO design toolkit (+a11y contrast issue)
$color-text-invert: $color-main-light;

// TODO: manage category colors and change naming
$gamme-list: (
    'quincaillerie': (
        $color-tertiary-1,
        $color-main-dark,
    ),
    'visserie': (
        $color-tertiary-4,
        $color-main-light,
    ),
    'outillage': (
        $color-tertiary-9,
        $color-main-light,
    ),
    'consommables': (
        $color-tertiary-8,
        $color-main-dark,
    ),
    'epi': (
        $color-tertiary-7,
        $color-main-dark,
    ),
    'equipements-atelier': (
        $color-tertiary-2,
        $color-main-light,
    ),
    'equipements-chantier': (
        $color-tertiary-6,
        $color-main-dark,
    ),
    'plomberie': (
        $color-tertiary-3,
        $color-main-dark,
    ),
    'amenagements-exterieurs': (
        $color-tertiary-5,
        $color-main-dark,
    ),
    'transmission-automatisme': (
        $color-tertiary-3,
        $color-main-dark,
    ),
    'acier': (
        $color-tertiary-10,
        $color-main-dark,
    ),
    'electricite': (
        $color-tertiary-11,
        $color-main-dark,
    ),
);

$color-scrollbar-track: #efefef; // TODO: use $color-white-dark-smoke instead && Remove redefinition of the same hexa in Descours
$color-scrollbar-bar: #b9b9b9;

$color-sidebar-customer-name: $color-secondary;
$color-sidebar-customer-account-number: $color-primary;

$color-sidebar-account-management: $color-secondary;

$color-info-price-banner: $color-blue-background;
$color-info-price-border: $color-primary;
$color-info-price-qty-text: $color-primary;
$color-info-price-promo: $color-red;
$color-info-price-qty-background: #f5f8ff; // Todo : remove as it seems not used
$color-qty-background: $color-primary-darken; // Todo : remove as it seems not used

$color-contact-faq-arrow: $color-primary;
$color-input-autocomplete-search-btn: $color-primary;
$color-input-autocomplete-search-border: $color-blue-dark;

// For resize purposes, the shape has been done in CSS instead of a SVG, which requires to define the background color.
$color-loyalty-shape-background: #dee5ed;

$color-form-border: $color-blue-dark;
